const routes = [
    { path: "/", name: "home", component: () => import("@/views/dashboard/Dashboard"), meta: { login_required: true } },
    { path: "/ui-catalog", name: "ui", component: () => import("@/views/UiCatalog"), meta: { login_required: true } },
    { path: "/settings", name: "settings", component: () => import("@/views/settings/Settings"), meta: { admin_route: true } },
    { path: "/ping", name: "ping", component: () => import("@/views/ping/Ping"), meta: { realtor_route: true } },
    { path: "/profile", name: "profile", component: () => import("@/views/user/Edit"), meta: { login_required: true } },
    { path: "/listings", name: "listing.list", component: () => import("@/views/listing/List"), meta: { login_required: true } },
    { path: "/listing/:id?", name: "listing.edit", component: () => import("@/views/listing/Edit"), meta: { login_required: true } },
    { path: "/listing/Intelligence/:id?", name: "listing.intelligence", component: () => import("@/views/listing/Intelligence"), meta: { realtor_route: true } },
    { path: "/my-listings", name: "listing.mine", component: () => import("@/views/listing/MyList"), meta: { realtor_route: true } },
    { path: "/showings", name: "listing_showing.list", component: () => import("@/views/listing_showing/List"), meta: { login_required: true } },
    { path: "/users", name: "user.list", component: () => import("@/views/user/List"), meta: { realtor_route: true } },
    { path: "/user/:id?", name: "user.edit", component: () => import("@/views/user/Edit"), meta: { realtor_route: true } }
]
export default routes
