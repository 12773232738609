import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"

Vue.use(VueRouter)
const { isNavigationFailure, NavigationFailureType } = VueRouter
const original_push = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    original_push.call(this, location).catch(error => {
        if(!isNavigationFailure(error, NavigationFailureType.duplicated)) {
            throw Error(error)
        }
    })
}

// Setup vue-router
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if(!!to && !!to.meta && !!to.meta.scroll_position) {
            to.meta.scroll_position = savedPosition
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
})

export default router
